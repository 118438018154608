<template>
    <div id="AboutWDOM">
        <div class="container">
            <div class="row logo">
                <img class="img-fluid" src="../assets/images/logo/logo_756.png" alt="logo">
            </div>
            <div class="row synopsis">
                <subtitle title="品牌简介" type='short'></subtitle>
                <p>{{AboutData.introduction}}</p>
            </div>

            <div class="row course">
                <subtitle title="品牌历程" type='short'></subtitle>
            </div>

            <div class="row course-list" v-for="(item,index)  in AboutData.nodeList" :key="index">
                <div class="course-content">
                    <div class="course-line"></div>
                    <div class="course-node">
                        <subtitle :title="item.title" type="small-title"></subtitle>
                        <ul>
                            <li v-for="(items,index) in item.intervalList" :key="index">
                                <div class="dot"></div>
                                <div class="node">
                                    <div :class="items.month?'node-content-plus':'node-content'">
                                        <p>{{items.year}}</p>
                                        <p v-if="items.month">{{items.month}}</p>
                                    </div>
                                    <p>{{items.content}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import subtitle from "../components/subtitle";
export default {
    data() {
        return {
            AboutData: {
                introduction:
                    "渥康（拉丁文：WDOM），新西兰高端乳品品牌。渥取意「丰盛」，康取意「富足」，渥康寓意「盛世繁华」。品牌所属集团公司旗下拥有众多生产工厂和牧场奶源，包括全球稀有的A2有机奶源和A2奶源，让渥康拥有研发高端乳品的硬实力。渥康一直秉持「创造未来乳品」的态度。2018年，渥康被新西兰《市场杂志》评为「新西兰第一品牌高端常温奶」。2019年，渥康荣获「新西兰国家食品奖」冠军。凭借不断的产品研发，渥康相继成为2017年「世界饮品创新奖」冠军和2021年「世界乳品创新奖」冠军。至今，已在全球20多个国家的高端乳品领域都有良好的表现。",
                nodeList: [
                    {
                        title: "早期历史",
                        intervalList: [
                            {
                                year: "1989",
                                content:
                                    "艾尔文为玻璃瓶装的鲜牛奶贴上以拉丁文命名的WDOM品牌标签，在纳皮尔小镇的超市、便利店和咖啡厅售卖。",
                            },
                            {
                                year: "1997",
                                content: "WDOM品牌推出了PET瓶装的纯牛奶。",
                            },
                            {
                                year: "1999",
                                content: "WDOM纯牛奶销往新西兰北岛的其它城市。",
                            },
                            {
                                year: "2005",
                                content: "WDOM纯牛奶销往新西兰南岛的部分城市。",
                            },
                        ],
                    },
                    {
                        title: "发展时期",
                        intervalList: [
                            {
                                year: "2012",
                                content:
                                    "WDOM品牌完成升级，重新定义品牌价值，指向高端消费人群。",
                            },
                            {
                                year: "2013",
                                content: "WDOM品牌取中文名，渥康。",
                            },
                            {
                                year: "2014",
                                content: "WDOM品牌推出了无乳糖奶。",
                            },
                            {
                                year: "2016",
                                content:
                                    "WDOM品牌推出了无化学添加剂的黑巧克力奶。",
                            },
                            {
                                year: "2017",
                                month: "9月",
                                content:
                                    "WDOM黑巧奶在德国慕尼黑独揽世界饮品创新奖的最佳儿童饮品奖金奖。",
                            },
                            {
                                year: "2017",
                                content:
                                    "WDOM首次以中文名，渥康的身份，经一般贸易登陆中国市场。",
                            },
                            {
                                year: "2018",
                                content:
                                    "WDOM渥康品牌受邀成立渥康京东自营旗舰店",
                            },
                            {
                                year: "2019",
                                content: "WDOM渥康品牌受邀成立渥康京东旗舰店。",
                            },
                            {
                                year: "2018",
                                content:
                                    "WDOM渥康无乳糖奶在新西兰获新西兰国家食品奖冠军提名；在波兰获世界乳品创新奖的最佳无添加乳品奖冠军提名；在德国获世界饮品创新奖的最佳乳饮品奖和最佳包装设计奖双项冠军提名；在日本获国际饮品奖的最佳品牌奖和最佳无添加饮品奖双项冠军提名；在新加坡获亚洲食品创新奖的最佳无添加食品奖和最佳包装设计奖双项冠军提名。",
                            },
                            {
                                year: "2018",
                                content:
                                    "WDOM渥康黑巧奶在波兰获世界乳品创新奖的最佳饮品奖冠军提名；在德国获世界饮品创新奖的最佳儿童饮品奖和最佳包装设计奖双项冠军提名；在日本获国际饮品奖的最佳品牌奖、最佳饮品概念奖和最佳无添加饮品奖三项冠军提名；在新加坡获亚洲食品创新奖的最佳儿童食品奖、最佳无添加食品奖和最佳包装设计奖三项冠军提名。",
                            },
                            {
                                year: "2018",
                                month: "9月",
                                content:
                                    "WDOM渥康鲜牛奶首次登陆中国市场，空运冷链，供应至中国部分城市的私立K12学校。",
                            },
                        ],
                    },
                    {
                        title: "多元化时期",
                        intervalList: [
                            {
                                year: "2019",
                                month: "2月",
                                content:
                                    "WDOM渥康品牌推出了无乳糖版本的黑巧奶。",
                            },
                            {
                                year: "2019",
                                month: "3月",
                                content:
                                    "WDOM渥康纯牛奶登陆新加坡、泰国、马来西亚等市场。",
                            },
                            {
                                year: "2019",
                                month: "4月",
                                content: "WDOM渥康纯牛奶登陆中东市场。",
                            },
                            {
                                year: "2019",
                                month: "6月",
                                content: "WDOM渥康纯牛奶登陆印度尼西亚市场。",
                            },
                            {
                                year: "2019",
                                month: "10月",
                                content:
                                    "WDOM渥康5.0%全脂纯牛奶独揽新西兰国家食品奖冠军。",
                            },
                            {
                                year: "2019",
                                month: "12月",
                                content:
                                    "WDOM渥康品牌联合其他品牌推出了儿童休闲零食产品。",
                            },
                            {
                                year: "2020",
                                month: "3月",
                                content:
                                    "WDOM渥康品牌积极抗击疫情，供应产品慰问疫情防控一线医护人员家属。",
                            },
                            {
                                year: "2020",
                                month: "4月",
                                content:
                                    "WDOM渥康品牌受邀登陆天猫国际进口超市。",
                            },
                            {
                                year: "2020",
                                month: "5月",
                                content: "WDOM渥康品牌受邀成立渥康天猫旗舰店。",
                            },
                            {
                                year: "2020",
                                month: "5月",
                                content: "WDOM渥康奶粉登陆中国市场。",
                            },
                            {
                                year: "2020",
                                month: "10月",
                                content:
                                    "WDOM渥康品牌受邀成立渥康天猫海外旗舰店。",
                            },
                            {
                                year: "2020",
                                month: "10月",
                                content:
                                    "WDOM渥康有机奶完成总时长24个月的有机审查，从有机农场到有机工厂再到有机产品的完整有机链认证，成功获得全球四大权威机构的有机认证（AsureQuality有机认证、BioGro有机认证、中检集团有机认证、中绿华夏有机认证），获得30个国家的有机准入资格（中国、新西兰、美国、奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克共和国、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典）。",
                            },
                            {
                                year: "2020",
                                month: "10月",
                                content:
                                    "WDOM渥康品牌受邀成为IFOAM国际有机农业运动联盟会员，全面接受IFOAM的国际有机管理体系监管。",
                            },
                            {
                                year: "2020",
                                month: "10月",
                                content:
                                    "WDOM渥康品牌受邀成为世界无乳糖组织会员。",
                            },
                            {
                                year: "2021",
                                month: "6月",
                                content: "WDOM渥康A2有机奶登陆中国市场。",
                            },
                            {
                                year: "2021",
                                month: "6月",
                                content:
                                    "WDOM渥康豆诺豆娜乳豆、渥康无乳糖奶分别获得2021年世界乳品创新奖的“最佳乳制品零食奖”冠军、“最佳吸收乳制品奖”冠军；渥康A2有机奶系列亦入围了“最佳乳饮品奖”金奖的评选。",
                            },
                        ],
                    },
                ],
            },
        };
    },
    components: {
        subtitle,
    },
};
</script>

<style scoped>
@media (min-width: 975px) {
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 164px;
        margin-right: 10px;
    }
    .logo > img {
        width: 756.67px;
        height: 200px;
    }
    .synopsis {
        margin-top: 164px;
    }
    .synopsis > p {
        margin-top: 26px;
        font-size: 28px;
        text-align: left;
    }
    .course {
        margin-top: 143px;
    }
    .course-content {
        display: flex;
        flex-direction: row;
    }
    .node {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
    }
    .dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: black;
        position: relative;
        bottom: 10px;
    }
    .node > div:nth-child(1) {
        font-size: 50px;
        margin-left: 30px;
    }
    .node-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    }
    .node-content > p:nth-child(1) {
        font-weight: bold;
    }
    .node-content > p:nth-child(2) {
        font-size: 36px;
    }
    .node-content-plus {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    }
    .node-content-plus > p:nth-child(1) {
        position: relative;
        font-weight: bold;
        top: 40px;
    }
    .node-content-plus > p:nth-child(2) {
        font-size: 34px;
        font-weight: 500;
        position: relative;
        top: 10px;
        left: 5px;
    }
    .node > p:nth-child(2) {
        font-size: 28px;
        margin-left: 30px;
        color: rgba(102, 102, 102, 1);
    }
    li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin-top: 50.5px;
    }
    .course-line {
        height: 100%;
        width: 5px;
        border-right: 1px solid rgba(166, 166, 166, 1);
        position: relative;
        left: 42px;
    }
    .course-node-title {
        font-size: 24px;
        color: rgba(51, 51, 51, 1);
        margin-top: 99.5px;
    }
}
@media (max-width: 991px) {
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 124px;
        position: relative;
        left: 18px;
    }
    .logo > img {
        width: 450px;
        height: 130px;
    }
    .synopsis {
        margin-top: 104px;
    }
    .synopsis > p {
        margin-top: 26px;
        font-size: 20px;
        text-align: left;
    }
    .course {
        margin-top: 103px;
    }
    .course-content {
        display: flex;
        flex-direction: row;
    }
    .node {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
    }
    .dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: black;
        position: relative;
        bottom: 10px;
    }
    .node > div:nth-child(1) {
        font-size: 36px;
        margin-left: 28px;
    }
    .node-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    }
    .node-content > p:nth-child(1) {
        font-weight: bold;
    }
    .node-content > p:nth-child(2) {
        font-size: 25px;
    }
    .node-content-plus {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    }
    .node-content-plus > p:nth-child(1) {
        position: relative;
        font-weight: bold;
        top: 40px;
    }
    .node-content-plus > p:nth-child(2) {
        font-size: 28px;
        font-weight: 500;
        position: relative;
        top: 10px;
        left: 5px;
    }
    .node > p:nth-child(2) {
        font-size: 28px;
        margin-left: 30px;
        color: rgba(102, 102, 102, 1);
    }
    li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin-top: 93.5px;
    }
    .course-line {
        height: 100%;
        width: 5px;
        border-right: 1px solid rgba(166, 166, 166, 1);
        position: relative;
        left: 42px;
    }
}
@media (max-width: 767px) {
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .logo > img {
        width: 250px;
        height: 70px;
    }
    .synopsis {
        margin-top: 54px;
    }
    .synopsis > p {
        margin-top: 26px;
        font-size: 16px;
        text-align: left;
    }
    .course {
        margin-top: 53px;
    }
    .course-content {
        display: flex;
        flex-direction: row;
    }
    .node {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
    }
    .node > div:nth-child(1) {
        font-size: 20px;
        margin-left: 28px;
    }
    .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: black;
        position: relative;
        bottom: 10px;
    }
    .node > p:nth-child(1) {
        font-size: 25px;
        font-weight: bold;
        margin-left: 10px;
    }
    .node > p:nth-child(2) {
        font-size: 12px;
        margin-left: 10px;
        color: rgba(102, 102, 102, 1);
    }
    li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin-top: 50.5px;
    }
    .course-line {
        height: 100%;
        width: 5px;
        border-right: 1px solid rgba(166, 166, 166, 1);
        position: relative;
        left: 37px;
    }

    .node-content-plus {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
    }
    .node-content-plus > p:nth-child(1) {
        position: relative;
        font-weight: bold;
        top: 20px;
    }
    .node-content-plus > p:nth-child(2) {
        font-size: 16px;
        font-weight: 500;
        position: relative;
        top: -5px;
        left: -1px;
    }
    .course-list{
        position: relative;
        right: 20px;
    }
}
</style>